<template>
  <v-row
    v-if="profile"
    justify="center"
  >
    <v-col
      cols="10"
      md="10"
      class="text-center"
    >
      <h3 class="mb-4">
        Your Details
      </h3>
      <p />
    </v-col>

    <v-col
      cols="10"
      md="10"
    >
      <e-info-card
        title="Profile"
        sub-title="Some information may be visible to staff administering the SCCA Portal"
      >
        <template slot="basic-info-slot">
          <v-expansion-panels class="my-4">
            <v-expansion-panel
              v-for="(item, i) in profile"
              :key="i"
              class=""
              elevation="1"
            >
              <v-expansion-panel-header class="border-b-2 border-gray-600">
                <div
                  class="d-flex justify-space-between sm:align-center align-start"
                >
                  <div
                    class="md:flex block justify-space-between sm:align-center align-start  flex-1"
                  >
                    <div
                      class=" flex-grow-1"
                      style="flex-basis:136px;"
                    >
                      <p class="text-uppercase text-caption ma-0 mb-3 mr-4">
                        {{ item.title }}
                      </p>
                    </div>
                    <div
                      class="flex-grow-1 text-left mr-2"
                      style="flex-basis:136px;"
                    >
                      <p class=" ma-0 ">
                        {{ item.subtitle }}
                      </p>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class=" font-weight-medium">
                {{ item.description }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </e-info-card>
    </v-col>
    <v-col
      cols="10"
      md="10"
    >
      <e-info-card title="Contact info">
        <template slot="basic-info-slot">
          <v-expansion-panels class="my-4">
            <v-expansion-panel
              v-for="(item, i) in contact"
              :key="i"
              class=""
              elevation="1"
            >
              <v-expansion-panel-header class="border-b-2 border-gray-600">
                <div
                  class="d-flex justify-space-between sm:align-center align-start"
                >
                  <div
                    class="md:flex block justify-space-between sm:align-center align-start  flex-1"
                  >
                    <div
                      class=" flex-grow-1"
                      style="flex-basis:136px;"
                    >
                      <p class="text-uppercase text-caption ma-0 mb-3 mr-4">
                        {{ item.title }}
                      </p>
                    </div>
                    <div
                      class="flex-grow-1 text-left mr-2"
                      style="flex-basis:136px;"
                    >
                      <p class=" ma-0 ">
                        {{ item.subtitle }}
                      </p>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class=" font-weight-medium">
                {{ item.description }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </e-info-card>
    </v-col>
    <v-col
      cols="10"
      md="10"
    >
      <e-info-card title="Active Products">
        <template slot="basic-info-slot">
          <v-expansion-panels class="my-4">
            <v-expansion-panel
              v-for="(item, i) in active_products"
              :key="i"
              class=""
              elevation="1"
            >
              <v-expansion-panel-header class="border-b-2 border-gray-600">
                <div
                  class="d-flex justify-space-between sm:align-center align-start"
                >
                  <div
                    class="md:flex block justify-space-between sm:align-center align-start  flex-1"
                  >
                    <div
                      class=" flex-grow-1"
                      style="flex-basis:136px;"
                    >
                      <p class="text-uppercase text-caption ma-0 mb-3 mr-4">
                        {{ item.title }}
                      </p>
                    </div>
                    <div
                      class="flex-grow-1 text-left mr-2"
                      style="flex-basis:136px;"
                    >
                      <p class=" ma-0 ">
                        {{ item.subtitle }}
                      </p>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class=" font-weight-medium">
                {{ item.description }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </e-info-card>
    </v-col>
  </v-row>
</template>
<script>
  import { mapGetters } from 'vuex'
  import EInfoCard from '@/components/card/BasicInfoCard'
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Registrant Info',
    },
    components: {
      EInfoCard,
    },
    data () {
      return {
        imgOne: require('@/assets/images/svg/search.svg'),
        accordion2: [
          {
            title: 'Contact Email',
            subtitle: 'support@distinctict.com.au',
            description: '',
          },
          {
            title: 'Mobile',
            subtitle: '+61 400 000 000',
            description: '',
          },
        ],
        accordion3: [
          {
            title: 'SCCA License',
            subtitle: 'Expires 2nd Jul 2021',
          },
        ],
      }
    },
    created () {
      this.$store.dispatch('getAllRegistrantOrders')
    },
    computed: {
      ...mapGetters(['loggedInUser', 'registrantOrders']),
      profile () {
        return [
          {
            title: 'Given Name',
            subtitle: this.loggedInUser.given_name,
            description: 'You cannot update your given name.',
          },
          {
            title: 'Surname',
            subtitle: this.loggedInUser.surname,
            description: 'You cannot update your surname.',
          },
          {
            title: 'Date of Birth',
            subtitle: this.loggedInUser.dob,
            description: 'You cannot change your date of birth.',
          },
          {
            title: 'Gender',
            subtitle: this.loggedInUser.gender,
          },
          {
            title: 'password',
            subtitle: `Last changed ${this.loggedInUser.passwordLastChanged}`,
          },
        ]
      },
      contact () {
        return [
          {
            title: 'Contact Email',
            subtitle: this.loggedInUser.email,
            description: '',
          },
          {
            title: 'Mobile',
            subtitle: this.loggedInUser.phone,
            description: '',
          },
          {
            title: 'Address',
            subtitle: this.loggedInUser.address_line,
            description: '',
          },
        ]
      },
      active_products () {
        if (!this.registrantOrders) {
          return []
        }

        return this.registrantOrders.map(record => {
          if (record.is_active) {
            return {
              title: record.product_name,
              subtitle: `Status: ${record.approval_status}`,
              description: `Purchase Date: ${record.purchase_date_time}`,
            }
          }

          return {
            title: record.product_name,
            subtitle: 'Inactive',
            description: `Purchase Date: ${record.purchase_date_time}`,
          }
        })
      },
    },
  }
</script>
